<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="4" v-if="vm">
        <v-form ref="form" @submit.prevent="validateBeforeSubmit">
          <v-card>
            <v-card-title>Budget item</v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="vm.title"
                    label="Title"
                    v-validate="'required'"
                    data-vv-as="title"
                    name="title"
                    :error-messages="errors.collect('title')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="vm.description" filled rows="1" label="Description" required></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="vm.budget"
                    label="budget"
                    v-validate="'required|decimal'"
                    data-vv-as="budget"
                    name="budget"
                    :error-messages="errors.collect('budget')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="vm.maxHrs"
                    label="Max hours"
                    v-validate="'numeric'"
                    data-vv-as="maxHrs"
                    name="maxHrs"
                    :error-messages="errors.collect('maxHrs')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="vm.maxEpisodes"
                    label="Max episodes"
                    v-validate="'numeric'"
                    data-vv-as="maxEpisodes"
                    name="maxEpisodes"
                    :error-messages="errors.collect('maxEpisodes')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="vm.maxQuote"
                    label="Max quote"
                    v-validate="'numeric'"
                    data-vv-as="maxQuote"
                    name="maxQuote"
                    :error-messages="errors.collect('maxQuote')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="8">
        <v-card>
          <v-card-title>
            Line Items
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">Add line item</v-btn>
              </template>
              <item-selector v-on:item-added="itemAdded" v-on:item-closed="itemClosed"></item-selector>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="vm.lineItems"
              item-key="index"
              class="alternate mr-2"
            >
              <template v-slot:item="{item,isSelected, select, index}">
                <tr>
                  <td>
                    <b>Title:</b>
                    {{ item.supportItem }}
                    <br />
                    <b>Number:</b>
                    {{ item.supportItemNumber}}
                    <span style="margin-left:40px">
                      <b>Unit of measure:</b>
                      {{ item.unitOfMeasure}}
                    </span>
                    <br />
                    <b>Description:</b>
                    {{ item.supportItemDescription}}
                  </td>
                  <td>
                    <v-checkbox v-model="item.nDISManaged" @change="save"></v-checkbox>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as budgetItemService from "../../../services/budgetItemService";
import * as utilsService from "../../../services/utilsService";

import itemSelector from "./item-selector";
Vue.use(VeeValidate);
export default {
  name: "budgetItemDetails",
  components: {
    "item-selector": itemSelector
  },
  data: () => ({
    vm: {},
    dialog: false,

    form: {},
    headers: [{ text: "Support Item" }, { text: "NDIS Managed" }, { text: "" }]
  }),
  props: {
    item: Object
  },
  watch: {
    item(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentBudgetItem() {
      return this.$store.getters.currentBudgetItem;
    }
  },
  methods: {
    init() {
      if (this.currentBudgetItem !== undefined) {
        console.log(this.currentBudgetItem);
        this.vm = this.currentBudgetItem;
      }
      if (this.vm.lineItems == undefined) {
        this.vm.lineItems = [];
      }
    },
    filterRegistrationGroups() {
      let me = this;
      me.filteredRegistrationGroups = [];

      if (this.vm.supportType != undefined) {
        me.vm.supportType.registrationGroups.forEach(x => {
          me.registrationGroups.forEach(y => {
            if (x == y.id) {
              me.filteredRegistrationGroups.push(y);
            }
          });
        });
      }
      return me.filteredRegistrationGroups;
    },
    close() {
      this.$emit("item-closed");
    },
    itemAdded(data) {
      data.forEach(item => {
        item.nDISManaged = true;
      });
      this.vm.lineItems = this.vm.lineItems.concat(data);
      this.save();
      this.itemClosed();
    },
    itemClosed() {
      this.dialog = false;
    },
    deleteItem(item) {
      const index = this.vm.lineItems.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.vm.lineItems.splice(index, 1);
        this.save();
      }
    },
    save() {
      let me = this;
      me.vm.isDeleted=false;
      budgetItemService
        .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
         
         
          if(utilsService.isEmpty(this.currentCase.hasBudgetItems) || this.currentCase.hasBudgetItems===false){
            this.currentCase.hasBudgetItems=true;
            this.$store.dispatch("setCurrentCase", this.currentCase);
          }
           me.$emit("item-added", me.vm);
        });
        me.$router.push("/participant-management/current-participant/budget-Items/details");
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save();
           this.$router.push("/participant-management/current-participant/budget-Items");
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
       this.$store.dispatch("setToast", {
            message: "Budget item saved",
            color: "primary"
          });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

