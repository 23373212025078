import Vue from "vue";

function getRef(orgId, caseId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("budgetItems");
}

export async function getById(orgId, caseId, id) {
  return getRef(orgId, caseId)
    .doc(id)
    .get();
}

export async function list(orgId, caseId) {
  return getRef(orgId, caseId)
  .where("isDeleted", "==", false)
  .get();
}
export async function listRemoved(orgId, caseId) {
  return getRef(orgId, caseId)
    .where("isDeleted", "==", true)
    .get();
}
export async function remove(orgId, caseId, id, user) {
  return getRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: true, deletedBy: user, deletedOn: new Date() });
}
export async function unRemove(orgId, caseId, id) {
  return getRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: false, deletedBy: null, deletedOn: null });
}

export async function save(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId, caseId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId, caseId).add(obj);
  }
}

export async function addSpent(orgId, caseId, id, data) {
  return getRef(orgId, caseId)
    .doc(id)
    .set({ spent: data }, {merge: true });
}