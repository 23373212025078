<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-card class="mr-2">
      <v-card-title class="headline grey lighten-2" primary-title>Line items</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              item-text="name"
              v-model="supportCategory"
              :items="lineItemDefinition.supportCategories"
              label="Support Category"
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-if="supportCategory!=null"
              v-model="registrationGroup"
              item-text="name"
              :items="selectableRegistrationGroups"
              label="Registration group"
              return-object
              @change="registrationGroupSelected"
            ></v-select>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col class="mt-4" cols="12">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="lineItems"
              :show-select="true"
              item-key="supportItemNumber"
              class="alternate elevation-1"
            >
              <template v-slot:item="{item,isSelected, select, index}">
                <tr>
                  <td>
                    <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
                  </td>
                  <td>
                    <b>Title:</b>
                    {{ item.supportItem }}
                    <br />
                    <b>Number:</b>
                    {{ item.supportItemNumber}}
                    <span style="margin-left:40px">
                      <b>Unit of measure:</b>
                      {{ item.unitOfMeasure}}
                    </span>
                    <br />
                    <b>Description:</b>
                    {{ item.supportItemDescription}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

import * as lineItemService from "../../../services/lineItemSevice";
Vue.use(VeeValidate);
export default {
  name: "notes-form",
  data: () => ({
    vm: {},
    lineItemDefinition: {},
    selected: [],
    lineItems: [],
    registrationGroup: null,
    supportCategory: null,
    supportCategories: [],
    headers: [{ text: "Support Item" }]
  }),
  watch: {},
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentBudgetItem() {
      return this.$store.getters.currentBudgetItem;
    },
    selectableRegistrationGroups() {
      return this.lineItemDefinition.registrationGroups.filter(
        x =>
          x.supportCategoryNumber == this.supportCategory.supportCategoryNumber
      );
    }
  },
  methods: {
    init() {
      this.selected = [];
      this.lineItemDefinition = {};
      lineItemService
        .list()
        .then(result => {
          result.docs.map(doc => {
            const lid = doc.data();
            const dt = new Date();
            const from = new Date(lid.effectiveFromDate.replace(/-/g, "/"));
            const to = new Date(lid.effectiveToDate.replace(/-/g, "/"));
           // console.log(from);
            //console.log(to);
            if(dt>=from && dt<=to){
              this.lineItemDefinition = lid;
              this.lineItemDefinition.id = doc.id;
              console.log(this.lineItemDefinition.id);
              console.log(this.lineItemDefinition.supportCategories);
            }
          });
        });
       
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.lineItems.slice();
      }
    },
    close() {
      this.$emit("item-closed");
    },
    registrationGroupSelected() {
      lineItemService
        .listBySupportCategoryAndRegistrationGroup(
          this.lineItemDefinition.id,
          this.supportCategory.supportCategoryNumber,
          this.registrationGroup.registrationGroupNumber
        )
        .then(results => {
          let items = [];
          results.docs.map(doc => {
            const item = doc.data();
            item.id = doc.id;
            items.push(item);
          });
          this.lineItems = items;
          console.log(this.lineItems);
        });
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          me.$emit("item-added", me.selected);
          me.selected = [];
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
     
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

